/* global window */
import { UserFactory, UserTypes, } from '@haaretz/htz-user-utils';
import log from 'loglevel';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, } from 'react';
import { useMutation, useQuery, } from 'react-apollo';
import { GET_USER, UPDATE_USER, } from './UserInjector';
import usePreview from '../../hooks/Page/usePreview';

export const userScheme = {
  type: 'anonymous',
  email: null,
  firstName: null,
  lastName: null,
  emailStatus: null,
  anonymousId: null,
  university: false,
  extendedUserType: null,
  __typename: 'UserHtz',
};

const isToday = date => {
  const today = new Date();

  return date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear();
};

const userTypeToNumber = user => (!user ? 0
  : user.type === UserTypes.registered ? 1
    : user.type === UserTypes.paying ? 2
      : 0);

const saveOutbrainUsertype = user => {
  const saveValue = () => {
    window.localStorage.setItem('outbrain', JSON.stringify({
      updateAt: new Date().toJSON(),
      userType: userTypeToNumber(user),
    }));
  };

  if (typeof window !== 'undefined' && user && user.type) {
    const outbrain = window.localStorage.getItem('outbrain');

    if (outbrain) {
      const { updateAt, userType, } = JSON.parse(outbrain);
      if (!isToday(new Date(updateAt)) || userType !== userTypeToNumber(user)) {
        saveValue();
      }
    }
    else {
      saveValue();
    }
  }
};

const propTypes = {
  render: PropTypes.func.isRequired,
};
const defaultProps = {};

function UserDispenser({ render, }) {
  const { isPreview, } = usePreview();
  const { error, loading, data, } = useQuery(GET_USER, { skip: isPreview, });

  const [updateUser,] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_USER, },],
  });

  const plantCookies = value => {
    const newUser = new UserFactory(true).build();

    const user = isPreview ? userScheme : { ...userScheme, ...newUser, };

    updateUser({
      variables: { user, },
    });

    return user;
  };

  const plantCookiesCallback = useCallback(plantCookies, []);

  if (error) {
    log.error(error);
    return null;
  }
  if (loading) return null;

  let { user, } = data || {};

  if (!user) {
    user = userScheme;
  }

  saveOutbrainUsertype(user);

  return (
    <Fragment>
      {render({
        isLoggedIn: !!(
          user
          && (user.type === UserTypes.paying
            || user.type === UserTypes.registered)
        ),
        user,
        plantCookies: plantCookiesCallback,
      })}
    </Fragment>
  );
}

export const useUser = () => {
  const { isPreview, } = usePreview();
  const { error, loading, data, } = useQuery(GET_USER, { skip: isPreview, });

  if (error) {
    log.error(error);
    return { isLoggedIn: false, user: userScheme, };
  }

  if (loading) return { isLoggedIn: false, user: userScheme, };

  let { user, } = data || {};

  if (!user) {
    user = userScheme;
  }

  saveOutbrainUsertype(user);
  return {
    isLoggedIn: !!(
      user
      && (user.type === UserTypes.paying
        || user.type === UserTypes.registered)
    ),
    user,
  };
};

UserDispenser.propTypes = propTypes;
UserDispenser.defaultProps = defaultProps;

export default UserDispenser;
