const EMAIL_MAX_LENGTH = 40;

export const teaserI18n = Object.freeze({
  ratingDescription: 'כוכבים',
  theTradeTitle: 'The Trade',
});

export const theTradeTeaserI18n = Object.freeze({
  title: 'The Trade',
  img: {
    src: 'https://www.themarker.com/tm/images/logos/excellence.png',
    alt: 'excellence logo',
  },
});

export const userStoryTopicI18n = Object.freeze({
  name: 'שם',
  namePlaceholder: 'אנא הזינו שם',
  location: 'מיקום',
  locationPlaceholder: 'אנא הזינו מהיכן אתם',
  content: 'תוכן',
  contentPlaceHolder: 'אנא הזינו תוכן',
  send: 'שלחו',
  successTitle: 'סיפורכם נשלח בהצלחה',
  successSubtitle: 'תודה על השיתוף!',
  successResetPre: 'לשליחת סיפור נוסף',
  successReset: 'לחצו כאן',
});

export const commonI18n = Object.freeze({
  correctEmailTypoText: 'האם התכוונת ל: ',
});

export const alertsI18n = Object.freeze({
  mobileAlertsText: 'עקוב',
  mobileAlertsTextFollow: 'עוקב',
  mobileAlertsTextUnfollow: 'עקוב',
  desktopAlertsText: 'התראות במייל',
  alertsTextA11y: ' קבל התראות בתיבת הדואר האלקטרוני שלך עבור כתבות מ',
  campainName: 'שלחו',
});

export const adsI18n = Object.freeze({
  adTitle: 'פרסומת',
});

export const seriesArticleI18n = Object.freeze({
  loadButton: Object.freeze({
    open: count => 'טען את כל הכתבות בסדרה',
    close: 'סגירת הרשימה',
    ariaText: x => `נוספו ${x} כתבות`,
  }),
  titlePrefix: 'סדרת כתבות: ',
});

export const zoominText = 'הגדל';
export const zoomoutText = 'הקטן';

export const textInputI18n = Object.freeze({
  requiredLong: 'שדה חובה',
  requiredShort: '*',
});

export const tagsElementI18n = Object.freeze({
  prefix: 'לחצו על הפעמון לעדכונים בנושא:',
  bioTitle: 'תיאור',
});

export const breadcrumbsI18n = Object.freeze({
  ariaLabel: 'מיקומך באתר',
});
export const clickTrackerI18n = Object.freeze({
  promotedContentLabel: 'תוכן מקודם',
});

export const navigationMenuI18n = Object.freeze({
  buttonText: 'ניווט',
  a11yTexts: Object.freeze({
    subMenu: (title, isOpen) => `${isOpen ? 'סגירת' : 'פתיחת'} תת-תפריט ${title}`,
  }),
});

export const mobileNavigationMenuI18n = Object.freeze({
  buttonText: 'ניווט',
  close: 'סגירת תפריט הניווט',
  open: 'פתיחת תפריט הניווט',
  home: 'לדף הבית',
  sectionsTitle: 'כל המדורים',
  siteName: 'הארץ',
  more: 'עוד במדור זה',
  back: 'חזרה',
  login: {
    text: 'התחברות',
    campaignDetailsText: 'להתחברות',
    campaignDetailsIcon: 'אייקון',
    utmContent: 'להתחברות',
  },
  subscribe: {
    text: 'לרכישת מינוי',
    campaignDetails: 'לרכישת מינוי',
  },
  promotedLink: {
    href: 'https://www.themarker.com/10most',
    text: '10 הנצפות',
  },
});

export const mobileQuickRegistrationI18n = Object.freeze({
  signedUpText: 'תודה שנרשמת',
  disclaimer: 'בהרשמה אני מסכימ/ה לתנאי השימוש באתר, וכן לקבלת דיוורים והצעות למנוי מאתר הארץ / TheMarker.',
});

export const headerSearchI18n = Object.freeze({
  buttonText: 'חיפוש',
  placeHolder: 'הקלידו לחיפוש באתר',
  a11yTexts: Object.freeze({
    close: 'לחצו לסגירת החיפוש',
    execSearch: 'לחצו כדי להריץ את החיפוש',
  }),
  queryUrl: query => `https://www.themarker.com/misc/search-results?text=${query}&searchType=textSearch`,
  searchHistoryLabel: 'חיפושים אחרונים',
  loadMore: 'עוד כתבות',
  noResultsText: 'לא נמצאו תוצאות',
  errorText: 'אירעה שגיאה',
  resultsListTitle: 'כתבות',
  resultsPageTitle: 'TheMarker - תוצאות חיפוש',
  resultsForPageTitle: 'TheMarker - תוצאות חיפוש עבור:',
});

export const hotTopicsI18n = Object.freeze({
  label: 'הנושאים החמים',
});

export const mobileSearchI18n = Object.freeze({
  buttonText: 'חיפוש',
  placeHolder: 'חיפוש',
  queryUrl: query => `https://www.themarker.com/misc/search-results?text=${query}&searchType=textSearch`,
});

export const osakaI18n = Object.freeze({
  backToHome: 'בחזרה לעמוד הבית',
  backToSection: 'בחזרה למדור',
  nextArticle: 'לכתבה הבאה במדור',
  promotedContent: 'תוכן מקודם',
});

export const commentI18n = Object.freeze({
  tags: Object.freeze({
    editorsPick: 'בחירת העורכים',
    usersPick: 'בחירת הגולשים',
  }),
  buttons: Object.freeze({
    readMoreBtnTxt: 'קראו עוד',
    replyBtnTxt: 'הגיבו',
    reportAbuseBtnTxt: 'דווחו כפוגעני',
    reportReceivedTxt: 'הדיווח התקבל',
    reportErrorTxt: 'אירעה שגיאה, נסו שוב',
  }),
  ariaTexts: Object.freeze({
    subCommentTxt: 'תגובה לתגובה',
  }),
  tooltip: Object.freeze({
    ratedUserText: rating => `ציון: ${rating}. הציון (בסולם של 1-100) מבוסס על איכות התכנים, מספר הלייקים והתגובות שהתקבלו. מנויים המעוניינים לקבל ציון מוזמנים לבחור כינוי קבוע`,
    verifiedUserText: 'אומת ע״י המערכת',
  }),
  commercial: 'פרסומת',
});

export const commentFormI18n = Object.freeze({
  nickName: Object.freeze({
    or: ' או ',
  }),
  buttons: Object.freeze({
    sendBtnTxt: 'שלחו',
    cancelBtnTxt: 'בטלו',
    haaretzSheliUrl: 'https://www.haaretz.co.il/personal-area/haaretz-sheli?shownickname',
    toggleUserBtnText: identified => (identified ? 'להוספת תגובה אנונימית' : 'להוספת תגובה מזוהה'),
    addUserNickName: 'לבחירת השם ככינוי קבוע',
    nonPayingNickNamePromotionBtn: 'גם אני רוצה',
    promotionUrl: buttonText => `https://promotion.haaretz.co.il/offers?htm_source=site&htm_medium=Talkbacks&htm_campaign=Nickname_Promotion&htm_content=${buttonText}`,
    nonPayingNickNameNoThanks: 'לא תודה',
    nickNameChangeNowTxt: 'החליפו',
    cancelNickName: 'בטלו',
    retrySetNicknameTxt: 'נסו שוב',
    confirmSetNickname: 'עדכנו',
    cancelSetNickname: 'בטלו',
  }),
  labels: Object.freeze({
    nameLabelTxt: 'שם',
    nicknameLabelTxt: 'כינוי',
    commentLabelTxt: 'תגובה',
    chooseNewNickname: 'בחרו כינוי חדש',
  }),
  notes: Object.freeze({
    nameNoteTxt: 'הזינו שם שיוצג באתר',
    nickNameNoteTxt: 'רוצים לשנות או לבטל את הכינוי? ',
    goToPersonalField: 'גשו לאזור האישי',
    commentNoteTxt: 'משלוח תגובה מהווה הסכמה ',
    termsOfUse: Object.freeze({
      text: 'לתנאי השימוש של אתר TheMarker',
      link: 'https://www.themarker.com/misc/site-policy',
    }),
  }),
  errorNotes: Object.freeze({
    nameErrorNoteTxt: 'חובה להזין שם',
    usedNicknameErrorNoteTxt: 'הכינוי שבחרת תפוס. יש לבחור כינוי אחר',
    nicknameErrorNoteTxt: 'השם שבחרת תפוס. יש לבחור שם אחר',
    nickNameChangeError: 'השינוי לא הצליח',
    commentErrorNoteTxt: 'נא להזין את תוכן התגובה',
    commentErrorTooLongNoteTxt: 'אין להזין יותר מ-1,000 תווים בתוכן התגובה',
  }),
  texts: Object.freeze({
    nonPayingNickNameAlertText:
      'מנויי TheMarker בדיגיטל יכולים לבחור כינוי קבוע, לקבל כוכבים וליהנות מפרסום מהיר של תגובות',
  }),
});

export const commentSentI18n = Object.freeze({
  buttons: Object.freeze({
    getNotificationsBtnTxt: 'עדכנו אותי',
    dontGetNotificationsBtnTxt: 'לא תודה',
    closeBtnText: 'סגירה',
    goBackBtnText: 'חזרה',
  }),
  labels: Object.freeze({
    emailLabelTxt: 'דוא"ל',
  }),
  notes: Object.freeze({
    emailNoteTxt: 'הזינו כתובת דוא"ל לקבלת התראות',
  }),
  errorNotes: Object.freeze({
    emailErrorNoteTxt: 'נא להזין כתובת דוא"ל תקינה',
  }),
  texts: Object.freeze({
    commentRecievedBoldText: 'תגובתך נקלטה בהצלחה, ',
    commentRecievedText: 'ותפורסם על פי מדיניות המערכת.',
    commentRecievedTextSecondRow: 'שלחו לי דוא"ל כשהתגובה שלי מפורסמת וכשמגיבים לתגובה שלי:',
    commentRecievedBoldTextThankYouPage: 'תודה!',
    commentRecievedTextThankYouPage: 'תגובתך נקלטה בהצלחה ותפורסם על פי מדיניות המערכת',
    commentDidNotRecieved: 'משהו השתבש והתגובה לא נקלטה במערכת שלנו.',
    nicknameAlreadyInUse: 'השם שנבחר כבר נמצא בשימוש.',
    tryAnotherName: 'אנא בחרו שם אחר',
    tryAgainText: 'שננסה שוב?',
  }),
});

export const commentsSectionI18n = Object.freeze({
  signin: {
    color: 'bodyText',
    fontFamily: 'base',
    signin: 'התחברו',
    text: 'כדי להצטרף לדיון.',
  },
  buttons: Object.freeze({
    loadMoreCommentsBtnText: 'עוד תגובות',
  }),
  selectItems: Object.freeze({
    dateDescendingItemTxt: 'מהאחרונה לראשונה',
    dateAscendingItemTxt: 'מהראשונה לאחרונה',
    commentRatingItemTxt: 'הצג לפי דירוג',
    editorsPickItemTxt: 'בחירת העורכים',
  }),
  texts: Object.freeze({
    chooseSortMethodText: 'סדרו את התגובות',
  }),
  likes: Object.freeze({
    like: 'אהבתי',
    dislike: 'לא אהבתי',
  }),
});

export const footerMobileListsI18n = Object.freeze({
  MobileList: [
    Object.freeze({
      text: 'רכשו מנוי',
      link: 'https://promotion.themarker.com/offers',
    }),
    Object.freeze({
      text: 'מדיניות פרטיות',
      link: 'https://www.haaretz.co.il/misc/privacy-policy',
    }),
    Object.freeze({
      text: 'תנאי שימוש',
      link: 'https://www.themarker.com/misc/site-policy',
    }),
    Object.freeze({
      text: 'צרו קשר',
      link: 'https://www.themarker.com/misc/contact-us',
    }),
    Object.freeze({
      text: 'פרסמו באתר',
      link: 'https://www.themarker.com/st/c/prod/global/mediaKit/haaretzHeb.html',
    }),
    Object.freeze({
      text: 'שירות למנויים',
      link: 'https://www.themarker.com/account/personal-area',
    }),
    Object.freeze({
      text: 'נגישות',
      sublinks: [
        Object.freeze({
          text: 'דיווח על בעיית נגישות',
          link: 'mailto:accessibility@haaretz.co.il',
        }),
        Object.freeze({
          text: 'הצהרת נגישות',
          link: 'https://www.themarker.com/misc/accessibility',
        }),
      ],
    }),
  ],
  ButtonName: Object.freeze({
    text: 'להורדת האפליקציה',
    androidAppUrl: 'https://bykf9.app.goo.gl/KeJT',
    iosAppUrl: 'https://bykf9.app.goo.gl/KeJT',
  }),
  Copyright: Object.freeze({
    text: '© כל הזכויות שמורות',
  }),
});

export const footerDesktopI18n = Object.freeze({
  ExpandedButton: Object.freeze({
    close: 'סגור',
    showMore: 'הצג עוד',
  }),
  Copyright: Object.freeze({
    firstRow:
      'חדשות, ידיעות מהארץ והעולם - הידיעות והחדשות בעיתון הארץ. סקופים, מאמרים, פרשנויות ותחקירי עומק באתר האיכותי בישראל',
    secondRow: '© כל הזכויות שמורות להוצאת עיתון הארץ בע"מ',
  }),
});

export const footerSocialLinksI18n = Object.freeze({
  facebook: 'https://www.facebook.com/TheMarkerOnline',
  twitter: 'https://twitter.com/themarker',
  androidApp: 'https://play.google.com/store/apps/details?id=com.themarker',
  appleApp: 'https://apps.apple.com/il/app/themarker-%D7%93%D7%94%D7%9E%D7%A8%D7%A7%D7%A8/id360938308',
  redmail: 'https://www.themarker.com/misc/redemail',
  rss: 'https://www.themarker.com/misc/rss',
});

export const newsletterI18n = Object.freeze({
  input: Object.freeze({
    maxLength: EMAIL_MAX_LENGTH,
    emptyEmailError: 'נא למלא כתובת דוא"ל',
    invalidEmailError: 'נא למלא כתובת דוא"ל תקינה',
    emailExistsError: 'כתובת המייל כבר רשומה. התחבר לאתר.',
    confirmNewslettersError: 'נא לאשר קבלת ניוזלטרים',
    alreadyExistError: 'כתובת האימייל שהזנת נמצאת בשימוש. אנא נסו כתובת אחרת',
    tooLongError: `כתובת המייל חורגת מהאורך של ${EMAIL_MAX_LENGTH} תווים`,
  }),
  buttons: Object.freeze({
    signUp: 'להרשמה',
    nonSubscribers: 'למינויים בלבד',
    newsletterConfirmedButton: Object.freeze({
      success: 'לרשימה המלאה',
      exist: 'לרשימה המלאה',
      pending: '',
      error: 'נסו שנית',
    }),
  }),
  subscribersOnly: Object.freeze({
    text: () => [
      'רוצים לקבל את הדיוור?',
      'רכשו מינוי דיגיטלי, ותיהנו מתוכן עשיר ומגוון שירותים למנויים בלבד',
    ],
    button: Object.freeze({
      text: 'לרכישה',
      href: 'https://promotion.themarker.com/offers',
      variant: 'salesOpaque',
    }),
  }),
  newsletterForm: Object.freeze({
    noteText: 'אנא הזינו כתובת אימייל',
    labelText: 'דוא"ל',
    moreNewsletterLink: 'https://bit.ly/newsletters-tm',
    termAndConditionText: Object.freeze({
      txt1: 'בהרשמה, אני מאשר/ת את',
      txt2: 'תקנון האתר',
      href: 'https://www.themarker.com/misc/site-policy',
      txt3: 'וכן קבלת המלצות קריאה, הצעות לרכישת מינוי ודיוור מהארץ-TheMarker',
    }),
  }),
  texts: Object.freeze({
    newsletterConfirmedTitleText: Object.freeze({
      success: 'תודה שנרשמת!',
      exist: 'תודה שנרשמת!',
      pending: 'תודה שנרשמת!',
      error: 'קרתה תקלה ברישום',
    }),
    newsletterConfirmedText: Object.freeze({
      success: 'יש לנו דיוורים נוספים שעשויים לעניין אותך',
      exist: 'יש לנו דיוורים נוספים שעשויים לעניין אותך',
      pending: 'לסיום תהליך ההרשמה עליך ללחוץ על הלינק בדוא"ל האימות שנשלח לכתובת שהזנת.',
      error: 'אנא נסו להרשם שוב במועד מאוחר יותר',
      moreNewsletters: 'לניוזלטרים נוספים',
      pendingWithGmail: ({
        txt1: 'לסיום תהליך ההרשמה עליך',
        txt2: 'ללחוץ על הלינק בדוא״ל',
        txt3: 'האימות שנשלח לכתובת שהזנת',
        href: signUpEmail => `https://mail.google.com/mail/u/${signUpEmail}/#search/from%3A(sysadmin@mc.haaretz.com)+in%3Aanywhere+newer_than%3A1h`,
      }),
    }),
    defaultTitleText: 'הירשמו עכשיו: סיכום כותרות הבוקר אצלכם במייל מדי יום',
  }),
  serverErrors: Object.freeze({
    emailExistsError: 'Error: The email address you have provided is already registered',
  }),
});

export const fryListI18n = Object.freeze({
  title: 'מרחבי הרשת',
});

export const errorPageI18n = Object.freeze({
  error404: Object.freeze({
    headline: 'נראה שהלכת לאיבוד',
    paragraph: 'אפשר ליהנות מהשקט או לחזור למציאות',
    button: Object.freeze({
      text: 'חזרה לעמוד הבית',
      href: 'https://www.themarker.com',
    }),
  }),
});

export const welcomePageI18n = Object.freeze({
  texts: Object.freeze({
    headerHighLighted: 'ברוכים הבאים',
    headerNormal: 'לחוויה המשודרגת של הארץ',
    bullets: [
      'מהיר יותר',
      'נוח לקריאה',
      'מותאם יותר למובייל',
      'פחות פרסומות',
      'ממשק תגובות משופר',
      'קל ומהיר יותר לשתף',
    ],
  }),
  buttonText: 'לעמוד החדש',
});

export const userMenuI18n = Object.freeze({
  buttonText: 'שלום',
  logout: 'התנתקות',
  contactUsUrl: 'https://www.themarker.com/misc/contact-us',
  contactUs: 'צרו קשר',
  menuPos: Object.freeze({
    start: '0',
  }),
  menuItems: Object.freeze([
    Object.freeze({
      name: 'שירות למנויים',
      biActionCode: 130,
      url: 'https://www.themarker.com/account/personal-area',
    }),
    Object.freeze({
      name: 'צרו קשר',
      biActionCode: 122,
      url: 'https://www.themarker.com/misc/contact-us',
    }),
    Object.freeze({
      name: 'ניוזלטרים',
      biActionCode: 131,
      url: '/newsletter/single?segmentId=0000017e-1b74-d2ef-a1ff-7ff4f9350000',
    }),
  ]),
  noUserData: 'התחברות',
});

export const a11yMenuI18n = Object.freeze({
  a11yToggle: state => `${state ? 'הפסקת' : 'הפעלת'} מצב ניגודיות`,
  animationToggle: isReduced => `${isReduced ? 'הפעלת' : 'עצירת'} אנימציה`,
  a11yTitle: 'תפריט נגישות',
  cancelRefresh: 'ביטול הרענון האוטומטי',
  menuItems: Object.freeze([
    Object.freeze({
      name: 'דיווח על בעיית נגישות',
      url: 'mailto:accessibility@haaretz.co.il',
    }),
    Object.freeze({
      name: 'הצהרת נגישות',
      url: 'https://www.themarker.com/misc/accessibility',
    }),
  ]),
});

export const zenTextI18n = 'קריאת זן';

export const BingI18n = Object.freeze({
  timeLeftToRead: 'הזמן שנותר לקריאה:',
  articlesLeftToRead: 'נותרו כתבות לקריאה: ',
  readingTime: 'זמן קריאה: ',
  articleCount: 'כתבות נוספות בנושא: ',
  homePageKicker: '',
});

export const readingListMenuI18n = Object.freeze({
  url: 'https://www.themarker.com/account/reading-list',
  a11yText: 'מעבר לרשימת הקריאה',
});

export const readingListPageI18n = Object.freeze({
  title: 'רשימת קריאה',
  types: Object.freeze({
    all: 'הכל',
    recipe: 'מתכונים',
    regular: 'כתבות',
  }),
  empty: articleType => `עדיין לא שמרת אף ${articleType === 'recipe' ? 'מתכון' : 'כתבה'}`,
  howToSaveBeforeIcon: articleType => `לשמירת ${articleType === 'recipe' ? 'מתכונים' : 'כתבות'} ברשימת הקריאה יש ללחוץ על האייקון`,
  howToSaveAfterIcon: 'שנמצא ברחבי האתר.',
  saveIconAlt: 'של הסימנייה',
});

export const readingListActionsI18n = Object.freeze({
  a11y: Object.freeze({
    addArticle: 'שמרו את הכתבה לרשימת הקריאה',
    removeArticle: 'הסירו את הכתבה מרשימת הקריאה',
    createAlert: 'צרו תזכורת לקריאת הכתבה',
    removeAlert: 'בטלו את התזכורת לקריאת הכתבה',
  }),
  addArticle: 'שמרו',
  removeArticle: 'הסירו',
  saved: 'הכתבה נשמרה.',
  capped: count => `רשימת הקריאה כוללת ${count} כתבות. עם שמירת כתבה זו, תימחק הכתבה הישנה ביותר.`,
  cappedCheckbox: 'אל תציגו לי הודעה זו שוב',
  cancel: 'ביטול',
  ok: 'הבנתי',

  alertQuestion: 'האם תרצו לקבל תזכורת למייל?',
  alertMe: 'הזכירו לי לקרוא את הכתבה',
  alertCanceled: 'התזכורת בוטלה',
  tomorrow: 'מחר',
  nextWeek: 'בשבוע הבא',
  closeDialog: 'לחצו לסגירה',
});

export const mastheadSubscriptionI18n = Object.freeze({
  url: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=button&htm_campaign=subscription&htm_campaign_type=subscription&htm_content=hello_default',
  upSellUrl: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=button&htm_campaign=upsell&htm_campaign_type=subscription&htm_content=hello_default',
  buyText: 'רכישת מינוי',
  upgradeText: 'שדרוג מינוי',
  a11yText: 'רכישת מינוי',
});


const serviceByMailConfirmationButton = Object.freeze({
  href: '/newsletter/single?segmentId=0000017e-1b74-d2ef-a1ff-7ff4f9350000',
  text: 'לחצו כאן',
});
export const serviceByMailI18n = Object.freeze({
  authorAlertServiceTitle: 'שלחו לי התראה על כל כתבה חדשה של ',
  tagAlertServiceTitle: 'שלחו לי התראה כשיש כתבה חדשה על ',
  inpEmailLabelText: 'דוא"ל',
  inpEmailNoteText: 'אנא הזינו כתובת אימייל',
  btnSubmitText: 'שלחו',
  btnCancelText: 'לא תודה',
  btnCloseText: 'סגור',

  inpEmailErrorRequired: 'יש להכניס כתובת דואר אלקטרוני',
  inpEmailErrorInvalid: 'כתובת דואר אלקטרוני אינה תקינה',

  confirmationMessageTag: Object.freeze({
    success: tag => 'תודה שנרשמת!',
    exist: tag => 'תודה שנרשמת!',
    pending: tag => 'תודה שנרשמת!',
  }),
  confirmationMessageAuthor: Object.freeze({
    success: name => 'תודה שנרשמת!',
    exist: name => 'תודה שנרשמת!',
    pending: name => 'תודה שנרשמת!',
  }),
  confirmationMessageSecondRow: Object.freeze({
    success: 'יש לנו דיוורים נוספים שעשויים לעניין אותך',
    exist: 'יש לנו דיוורים נוספים שעשויים לעניין אותך',
    pending: 'לסיום תהליך ההרשמה עליך ללחוץ על הלינק בדוא"ל האימות שנשלח לכתובת שהזנת.',
  }),
  confirmationButton: Object.freeze({
    success: serviceByMailConfirmationButton,
    exist: serviceByMailConfirmationButton,
  }),
  registrationfailureDefaultMessage: 'ההרשמה נכשלה, אנא נסו שנית מאוחר יותר.',

  campainName: 'שלחו',
});

export const mobileAdditionalShare = Object.freeze({
  text: 'שיתוף',
});

export const shareBarI18n = Object.freeze({
  linkToComments: 'מעבר לטוקבקים',
  facebookToolTip: 'שתפו בפייסבוק',
  auhtorFacebookToolTip: 'לעמוד הפייסבוק',
  fbMessengerToolTip: 'שתפו כתבה במסנג\'ר',
  emailToolTip: 'שתפו כתבה במייל',
  authorEmailToolTip: 'שלחו מייל',
  whatsappToolTip: 'שתפו בווטסאפ',
  gitfToolTip: 'שתפו וחבריכם יקראו את הכתבה ללא תשלום',
  printToolTip: 'הדפיסו כתבה',
  twitterToolTip: 'שתפו בטוויטר',
  authorTwitterToolTip: 'לעמוד הטוויטר',
  redditTooltip: 'שתפו ברדיט',
  googlePlusTooltip: 'שתפו בגוגל פלוס',
  telegramPlusTooltip: 'שתפו בטלגרם',
  commentsPlusTooltip: 'מעבר לטוקבקים',
  mobileChangingShareButton: 'telegram',
  giftToolTip: 'שליחת הכתבה במתנה',
  mobileFirstShareButtons: [{ name: 'whatsapp', }, { name: 'gift', round: true, },],
  shareButtons: ({ theme, isMouseStory, isLabel, }) => ({
    startButtons: [
      {
        name: 'facebookLogo',
        buttonStyles: {
          color: theme.color('facebook'),
          ':hover': {
            color: theme.color('facebook'),
          },
        },
      },
      {
        name: 'whatsapp',
        buttonStyles: {
          color: theme.color('whatsapp'),
          ':hover': {
            color: theme.color('whatsapp'),
          },
        },
      },
      {
        name: 'mail',
        buttonStyles: {
          color: theme.color('shareBar', 'button'),
          ':hover': {
            color: theme.color('shareBar', 'buttonHover'),
          },
        },
      },
      {
        name: 'gift',
        buttonStyles: {
          color: theme.color('shareBar', 'button'),
          ':hover': {
            color: theme.color('shareBar', 'buttonHover'),
          },
        },
      },
    ],
    authorButtons: ({ authorEmail, authorFacebook, authorTwitter, }) => [
      ...(authorFacebook ? [
        {
          name: 'facebookLogo',
          buttonStyles: {
            color: theme.color('facebook'),
            ':hover': {
              color: theme.color('facebook'),
            },
          },
        },
      ] : []),
      ...(authorTwitter ? [
        {
          name: 'twitter',
          buttonStyles: {
            color: theme.color('twitter'),
            ':hover': {
              color: theme.color('twitter'),
            },
          },
        },
      ] : []),
      ...(authorEmail ? [
        {
          name: 'mail',
          buttonStyles: {
            color: theme.color('shareBar', 'button'),
            ':hover': {
              color: theme.color('shareBar', 'buttonHover'),
            },
          },
        },
      ] : []),
    ],
    endButtons: [
      ...(isMouseStory || isLabel ? [] : ['comments',]),
      ...(!isLabel ? [
        {
          name: 'save',
          buttonProps: { size: 4.2, },
        },
      ] : []),
      'zen',
      'print',
    ],
  }),
  magazineShareButtons: ({ theme, isPhotoBlog, isLabel, themeColor, }) => ([
    {
      name: 'whatsapp',
      buttonStyles: {
        color: theme.color(themeColor, 'shareBarButton'),
        ':hover': {
          color: theme.color(themeColor, 'shareBarButtonHover'),
        },
      },
    },
    ...(isLabel ? [] : ['gift', { name: 'comments', textMiscStyles: { color: 'inherit', }, },]),
    'print',
    ...(!theme.mobileReadingList
      ? []
      : [
        {
          name: 'save',
          textMiscStyles: { color: 'inherit', },
          buttonProps: { size: 4.2, },
        },
      ]),
    {
      name: 'zen',
      textMiscStyles: { color: 'inherit', },
      buttonText: theme.zenTextI18n,
    },
  ]),
  faceShareButtons: () => ([]),
  timelineShareButtons: ({ theme, isBlackWhite, }) => ([
    {
      name: 'facebookLogo',
      buttonStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : theme.color('facebook'),
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : theme.color('facebook'),
        },
      },
    },
    {
      name: 'whatsapp',
      buttonStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : theme.color('whatsapp'),
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : theme.color('whatsapp'),
        },
      },
    },
    'mail',
    {
      name: 'gift',
      buttonStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : theme.color('primary'),
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : theme.color('primary'),
        },
      },
    },
    { name: 'comments', textMiscStyles: { color: 'inherit', }, },
    'print',
    ...(!theme.mobileReadingList
      ? []
      : [
        {
          name: 'save',
          textMiscStyles: { color: 'inherit', },
          buttonStyles: isArticleSaved => ({
            minWidth: '12rem',
            ...(isArticleSaved
              ? {
                color: theme.color('neutral', '-10'),
                ':hover': {
                  color: theme.color('neutral', '-10'),
                  backgroundColor: isBlackWhite
                    ? theme.color('quaternary')
                    : theme.color('secondary'),
                },
              }
              : {}),
          }),
        },
      ]),
  ]),
  twentyQuestionsShareButtons: ({ theme, }) => ([
    {
      name: 'facebook',
      round: true,
    },
    {
      name: 'twitter',
    },
    {
      name: 'whatsapp',
    },
  ]),
  emailSubjectSuffix: '',
});

export const mobileUserMenuI18n = Object.freeze({
  noUserData: 'כניסה',
  userLoggedIn: 'יציאה',
  url: 'https://login.themarker.com',
});

export const mobileReadingList = Object.freeze({
  url: 'https://www.themarker.com/account/reading-list',
  text: 'רשימת קריאה',
});

export const selectAriaLabel = Object.freeze({
  text: 'פתח תפריט',
});

export const homepageLogoLink = Object.freeze({
  a11yText: 'חזרה לעמוד הבית',
});

export const greetingsI18n = Object.freeze({
  morning: 'בוקר טוב',
  noon: 'צהריים טובים',
  afternoon: 'אחה"צ טובים',
  evening: 'ערב טוב',
  night: 'לילה טוב',
  default: 'שלום',
});

export const galleryI18n = Object.freeze({
  open: 'עברו לתצוגת גלריה',
  captionPrefix: (current, total) => `${current} מתוך ${total} | `,
  mobileList: total => `${total} תמונות`,
});

export const topNewsI18n = Object.freeze({
  title: 'בראש החדשות:',
  externalId: 'com.tm.content.TopNewsElement',
});

export const commentsCountI18n = Object.freeze({
  countSuffix: ' תגובות לכתבה',
});

export const podcastI18n = Object.freeze({
  includePodcast: 'הכתבה מכילה פודקאסט',
  rssLinkText: 'להרשמה לפודקאסט',
  allEpisodesText: 'לכל הפרקים',
  allEpisodesUrl: 'https://www.haaretz.co.il/digital/podcast',
  listen: 'האזינו',
});

export const commercialListI18n = Object.freeze({ text: 'שיתופי פעולה', });

export const recipeInstructionsI18n = Object.freeze({
  sectionTitle: 'הוראות הכנה',
});
export const recipeIngredientsI18n = Object.freeze({
  sectionTitle: 'רכיבים',
});

export const recipeRatingI18n = Object.freeze({
  levelText: Object.freeze({
    Easy: 'קל',
    Medium: 'בינוני',
    Hard: 'קשה',
  }),
  highlightedText: Object.freeze({
    level: 'דרגת קושי',
    portions: 'מנות',
    time: 'זמן בישול',
  }),
  ratingTitle: 'דירוג הגולשים',
});

export const recipesSearchI18n = Object.freeze({
  placeHolder: 'מה מתחשק לך לבשל או לאפות?',
});

export const reviewRatingI18n = Object.freeze({
  ratingTitle: 'דירוג',
});

export const ratingI18n = Object.freeze({
  a11yTexts: Object.freeze({
    rateAction: rate => `לחץ כדי לדרג ב-${rate} כוכבים`,
    rating: rating => `${rating} כוכבים`,
  }),
});

export const liveBlogI18n = Object.freeze({
  liveUpdate: 'LIVE',
  updatesTitle: 'עדכונים',
  linkCopied: 'הקישור הועתק',
  loadMoreCommentsBtnText: 'טענו עוד',
  loadMoreItemsBtnText: 'לכל העדכונים',
  firstToLastLabel: 'מהראשון לאחרון',
  lastToFirstLabel: 'מהאחרון לראשון',
  sortBy: 'סדר לפי',
  keyEvents: 'אירועי מפתח',
  expand: 'להרחבה',
  close: 'סגרו',
  updatesButtonText: updatesCount => (updatesCount < 1
    ? ''
    : updatesCount === 1
      ? 'נוסף עדכון חדש'
      : `נוספו ${updatesCount} עדכונים חדשים`),
});

export const articleLayoutI18n = Object.freeze({
  commentSectionTitle: 'תגובות',
});

export const breakingNewsStrip = Object.freeze({
  url: '/misc/breaking-news',
  title: 'מבזקים',
  pause: 'עצור',
  play: 'הפעל',
  toPrevItem: 'למבזק קודם',
  toNextItem: 'למבזק הבא',
  externalId: 'TheMarker.Element.BreakingNewsElement',
});

export const dayMap = Object.freeze({
  0: 'ראשון',
  1: 'שני',
  2: 'שלישי',
  3: 'רביעי',
  4: 'חמישי',
  5: 'שישי',
  6: 'שבת',
});

export const marketingTools = Object.freeze({
  EmailConfirmation: {
    buttonText: 'לחצו כאן',
    buttonTextAfterClick: 'תודה',
    text1: 'זה באמת אתם?',
    text2: 'כדי לקרוא בחינם 6 כתבות בחודש יש לבצע אימות',
    text2AfterClick: 'יש לאשר את ההודעה מתוך תיבת האימייל שלך',
  },
  Weekly: {
    buttonText: 'לפרטים נוספים',
    text1: 'כל תכני הארץ בכל מכשיר בכל זמן',
    text2: 'פחות פרסומות ובאנרים',
    weeklyLink: '',
    url:
      'https://promotion.themarker.com/offers?offer=sale-htz',
  },
});

export const footballHeaders = Object.freeze({
  headers: ['קבוצה', "מש'", "נצ'", "הפ'", "תי'", 'הפרש', "נק'",],
});

export const groupBarTabs = Object.freeze({
  headers: ['בית 1', 'בית 2', 'בית 3', 'בית 4', 'בית 5', 'בית 6', 'בית 7', 'בית 8',],
});

export const nbaHeaders = Object.freeze({
  desktop: ['קבוצה', 'ניצחונות', 'הפסדים', 'אחוז',],
  mobile: ['קבוצה', "נצ'", "הפ'", 'אחוז',],
});

export const playoffsI18n = Object.freeze({
  top: 'פלייאוף עליון',
  bottom: 'פלייאוף תחתון',
});

export const btnOptions = Object.freeze({
  title: {
    open: 'הסתר',
    close: 'טען עוד',
  },
  degree: {
    open: 90,
    close: 270,
  },
});

export const coastHeaders = Object.freeze({
  east: 'מזרח',
  west: 'מערב',
});

export const survey = Object.freeze({
  button: 'לכל הסקרים',
});

export const surveyPie = Object.freeze({
  title: 'מפת הגושים',
  redLegend: 'ימין ודתיים',
  blueLegend: 'שמאל, מרכז וערבים',
});

export const timeLabels = Object.freeze({
  modDateAtText: 'עודכן ב-',
  modDateOnText: 'עודכן ב-',
  sameDayTimeFormat: 'HH:mm',
  timeFormat: 'dd בMMMM yyyy',
  fullDate: 'HH:mm, dd בMMMM yyyy',
  fullDateBot: 'HH:mm, dd בMMMM yyyy',
  liveBlogTime: 'HH:mm',
  dateFormat: 'dd',
  monthFormat: 'M',
});

export const zenNonPayingUsers = Object.freeze({
  title: 'קריאת זן זמינה למנויים בלבד',
  subTitle: 'ללא פרסומות ובהגשה נוחה לקריאה',
  button: 'לרכישת מינוי',
  buttonUrl: buttonText => `https://promotion.themarker.com/offers?htm_source=site&htm_medium=pop_up&htm_campaign=Zen&htm_content=${buttonText}`,
});

export const printNonPayingUsers = Object.freeze({
  title: 'הדפסת כתבה זמינה למנויים בלבד',
  subTitle: 'ללא פרסומות ותמונות, ובהגשה נוחה להדפסה',
  button: 'לרכישת מינוי',
  buttonUrl: buttonText => `https://promotion.themarker.com/offers?htm_source=site&htm_medium=pop_up&htm_campaign=printer_friendly&htm_campaign_type=subscription&htm_content=${buttonText}`,
});

export const readingListDisabled = Object.freeze({
  title: 'שמירת כתבות זמינה למנויים בלבד',
  subTitle: 'ללא פרסומות ובהגשה נוחה לקריאה',
  button: 'לרכישת מנוי',
  href: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=pop_up&htm_campaign=reading_list&htm_content=לרכישת מנוי',
});

export const adBlockPage = Object.freeze({
  title: 'שמנו לב שחוסם הפרסומות שלך מופעל',
  subTitle1: 'כדי להמשיך ליהנות מעיתונות איכותית ולתמוך בקיומה, אנא כבו את חוסם הפרסומות.',
  subTitle2: 'אפשרות מומלצת אחרת היא לרכוש מינוי דיגיטלי ולגלוש באתר באופן  חופשי',
  button: 'לרכישה',
  buttonUrl: 'https://promotion.themarker.com/offers?htm_medium=block-page&htm_campaign=subscripion&htm_campaign_type=purchase&htm_content=adblock-default',
  loginText: 'כבר מנויים?',
  loginUrl: url => `?htm_medium=link&htm_campaign=adblock-subscribe&htm_campaign_type=login&htm_content=adblock-default${url ? `&returnTo=${url}` : ''}`,
  loginUrlText: 'התחברו לאתר',
  instructionsTitle: 'לכיבוי חוסם הפרסומות',
  instructionsList: [
    'הקליקו על תוסף ה-Adblock בחלק העליון של הדפדפן',
    'בחרו ב"אל תריץ בעמודים באתר זה" / "Don\'t run on pages on this site"',
    ' לחזרה לכתבה ולגלישה חופשית ',
  ],
});

export const audioPlayerI18n = Object.freeze({
  ariaValueText: (currentValue, totalValue) => `${currentValue} מתוך ${totalValue}`,
  play: 'לחצו כדי לנגן',
  pause: 'לחצו כדי להשהות את הניגון',
  mute: 'לחצו להשתקה',
  unmute: 'לחצו להשמעה',
  changeRate: (from, to) => `לחצו כדי לשנות את מהירות הניגון מ-${from} ל-${to}`,
  skip: by => `דלגו ${Math.abs(by)} שניות ${by < 0 ? 'אחורה' : 'קדימה'}`,
  exclusiveFontWeight: 400,
  titleFontWeight: exclusive => 700,
  titleWebView: channelLabel => `האזינו לפודקאסט "${channelLabel}"`,
});

export const podcastChannelTitle = 'האזינו לפודקאסט בפלטפורמות השונות';

export const podcastChannelTitleMobile = 'האזינו לפודקאסט בחנויות או באפליקציית TheMarker:';

export const podcastChannelNames = {
  application: 'באפליקצייה',
  apple: 'אפל',
  spotify: 'ספוטיפיי',
  google: 'גוגל פודקאסטס',
};

export const seoI18n = Object.freeze({
  titlePrefix: ' - TheMarker',
});

export const promotionsI18n = Object.freeze({
  baseUrl: 'https://promotion.themarker.com',
});

export const paywallI18n = Object.freeze({
  or: 'או',
  sendMailConfirmationSite: 'themarker',
  sendMailConfirmationType: 'THEMARKER_MAIL_VALIDATION',
  sendMailConfirmationSuccess: 'מייל האימות נשלח. יש ללחוץ על כפתור האישור להמשך הגלישה באתר',
  sendMailConfirmationFailed: 'לא הצלחנו לשלוח אליך מייל אימות.',
  sendMailConfirmationTitle: 'עדיין לא אימתת את כתובת המייל שלך',
  sendMailConfirmationSubtitle: 'על מנת ליהנות מ-6 כתבות חינם בחודש, יש לאמת תחילה את כתובת המייל שלכם',
  trialSendMailConfirmationTitle: 'רוצים להינות ממינוי ההיכרות שלכם?',
  trialSendMailConfirmationSubtitle: 'אמתו את כתובת המייל שלכם על מנת להתחיל',
  emailAgain: 'לקבלת מייל אימות',
  sent: 'נשלח',
  signUp: 'להרשמה',
  termsText: 'אני מאשר/ת קבלת המלצות קריאה, הצעות לרכישת מינוי ודיוור מאתרי הארץ-TheMarker',
  close: 'סגירה',
});

export const paywallMiniregI18n = Object.freeze({
  signUp: 'הרשם',
  promotionaText1: 'קבל גישה לכתבה זו',
  terms: `אנו נשלח לכם ניוזלטר יומי עם כתבות אשר תרצו לקרוא.
  בהרשמתכם, ההנכם מסכימים לתנאי השימוש באתר "הארץ"`,
  alredyRegistered1: 'ברוכים השבים! הנכם כבר רשומים',
  alredyRegistered2: 'להמשך הגלישה באתר ',
});

export const paywallPhoneForArticleI18n = Object.freeze({
  sendPhone: Object.freeze({
    title: 'כתבה ראשונה עלינו',
    text: 'אנא הזינו את מספר הטלפון הנייד כדי לקרוא את הכתבה המלאה ללא תשלום',
    tinyText: 'כבר רשומים? ',
    tinyUrlText: 'התחברו',
    label: 'טלפון נייד',
    noteText: 'אנא הזינו מספר טלפון נייד',
    inputErrorText: 'יש להזין מספר טלפון נייד תקין',
  }),
  validateMobile: Object.freeze({
    title: null,
    text: 'אנא הזינו את הקוד שקיבלתם ב-SMS',
    tinyText: 'לא קיבלתם SMS? ',
    tinyUrlText: 'לחצו לשליחת הקוד פעם נוספת',
    label: 'קוד זיהוי',
    noteText: 'הזינו את הקוד שקיבלתם ב-SMS',
    inputErrorText: 'יש להזין את הקוד שקיבלתם ב-SMS',
  }),
  openingArticle: 'הכתבה תפתח בעוד כמה רגעים...',
  errors: Object.freeze({
    validationFailed: 'בדקו את הקוד ונסו שוב',
    smsFailed: 'אין באפשרותנו לשלוח הודעות SMS נוספות למספר טלפון זה',
  }),
});

export function tldrDefaultContent(tldrWordCount) {
  return Object.freeze({
    cookie: 'Tm',
    titleRegular: 'רוצים לקרוא תקציר של הכתבה?',
    titlePusr: `תקציר הכתבה ב-${tldrWordCount} מילים`,
    titleDefault: 'תקציר הכתבה עד 100 מילים',
    text: 'רכשו מינוי דיגיטלי ותיהנו מקריאה ללא הגבלה ושירותים הזמינים למנויים בלבד',
    purchaseButton: 'כן, אני רוצה',
  });
}

export const tldrPromotionLink = 'https://promotion.themarker.com/?htm_source=site&htm_medium=button&htm_campaign=TLDR&htm_content=subscribe';

export const timeDuration = Object.freeze({
  millis: 'אלפיות',
  seconds: 'שניות',
  minutes: 'דקות',
  hours: 'שעות',
  days: 'ימים',
  weeks: 'שבועות',
  months: 'חודשים',
  years: 'שנים',
});

export const timeDurationEmpty = Object.freeze({
  millis: '',
  seconds: '',
  minutes: '',
  hours: '',
  days: '',
  weeks: '',
  months: '',
  years: '',
});

export const magazineExclusivesI18n = Object.freeze({
  photoBlog: Object.freeze({}),
  familyAlbum: Object.freeze({}),
});

export const faceExclusivesI18n = Object.freeze({
  faceAffairs: Object.freeze({}),
});

export const imageGallery = Object.freeze({
  rightArrow: 'הקודם',
  leftArrow: 'הבא',
});

export const validationMailStrip = Object.freeze({
  headerText: 'כדי להמשיך לגלוש באתר יש לאמת את המייל',
  buttonText: 'לאימות המייל',
  successText: 'תודה, המייל נשלח בהצלחה',
});

export const words = Object.freeze({
  and: 'ו',
});

export const tateI18n = Object.freeze({
  loadMoreBtn: 'עוד כתבות',
});


export const infographicsI18n = Object.freeze({
  columns: Object.freeze({
    captionPrefix: 'מקור',
  }),
  timeline: Object.freeze({
    start: 'calc(-3rem - 1px)',
  }),
});


export const algoliaI18n = Object.freeze({
  searchAuthorsPrefix: ' לכל הכתבות של',
});

export const commentsPaywallBlocker = Object.freeze({
  subscribeUrl: 'https://promotion.haaretz.com/offers?htm_source=site&htm_campaign=talkbacks&htm_medium=button&htm_content=subs_join_faster&htm_campaign_type=purchase',
  loginUrl: 'https://login.haaretz.com/?htm_source=site&htm_campaign=talkbacks&htm_medium=button&htm_content=subs_join_faster&htm_campaign_type=purchase',
  title: 'הצטרפו לשיחה',
  subTitle: 'הצטרפו לשיחה',
  offerText: '4.90 לחודש הראשון',
  subscribe: 'לרכישה',
  askIfSignUp: 'כבר רשומים?',
  logIn: 'התחברות',
});

export const labelsI18n = Object.freeze({
  promotedLabelsContent: 'Labels',
  promotedContent: 'תוכן שיווקי',
  backToSection: 'בחזרה למדור',
  cooperateWith: companyName => `בשיתוף ${companyName}`,
  cooperateWithTooltip: companyName => `תוכן ממומן בשיתוף ${companyName}`,
  tooltipInfo: 'הכתבה הופקה ונערכה על ידי המחלקה המסחרית של The Marker',
  advertorialContent: 'תוכן שיווקי',
});

export const authorPage = Object.freeze({
  bioTitle: 'ביוגרפיה',
});

export const gameArticleI18n = Object.freeze({
  liveUpdate: 'LIVE',
  header: Object.freeze({
    penaltyHeb: 'פ',
    penaltyEng: 'P',
    ownGoalHeb: 'ע',
    ownGoalEng: 'O',
    status: Object.freeze({
      notStarted: 'טרם התחיל',
      live: 'חי',
      halfTime: 'הפסקת מחצית',
      fullTime: 'הסתיים',
      extraTime: 'הארכה',
      penaltyShootout: '',
      finishedAfterExtraTime: 'הסתיים (אחרי הארכה)',
      regularTimeFinished: 'הסתיים המועד החוקי',
      fullTimeAfterPenalties: 'הסתיים (אחרי פנדלים)',
      cancelled: 'בוטל',
      postPoned: 'נדחה',
      interrupted: 'הופסק',
      abandoned: 'הופסק',
      suspended: 'הופסק',
      awarded: 'הוכרע',
      delayed: 'הופסק',
      toBeAnnounced: 'יעודכן בהמשך',
      walkOver: 'הוכרע',
      awaitingUpdates: 'יעודכן בהמשך',
    }),
  }),
  subHeader: Object.freeze({
    viewers: 'צופים',
    coach: 'שופט ראשי',
    round: 'מחזור',
  }),
  coverage: {
    title: 'סיקור המשחק',
  },
  standings: {
    title: 'טבלה',
  },
  events: Object.freeze({
    title: 'אירועי המשחק',
    type: Object.freeze({
      yellowcard: 'כרטיס צהוב',
      substitution: ({ teamName, isEng, }) => `חילוף ב${isEng ? '-' : ''}${teamName}`,
      redcard: 'כרטיס אדום',
      goal: ({ teamName, score, isEng, }) => `שער! ${score} - ל${isEng ? '-' : ''}${teamName}`,
      'own-goal': ({ teamName, }) => `גול עצמי! ${teamName}`,
      missed_penalty: ({ teamName, }) => 'פנדל הוחמץ!',
      penalty: ({ teamName, score, isEng, }) => `שער! ${score} - ל${isEng ? '-' : ''}${teamName}`,
      'opening-whistle': 'שריקת הפתיחה',
    }),
    penalty: 'פנדל',
  }),
  lineups: Object.freeze({
    title: 'הרכבים',
    coach: 'מאמן:',
    replace: 'מחליפים',
  }),
  stats: Object.freeze({
    title: 'סטטיסטיקות',
    totalShots: 'בעיטות לשער',
    ongoalShots: 'בעיטות למסגרת',
    fouls: 'עבירות',
    corners: 'קרנות',
    offsides: 'נבדלים',
    redCards: 'כרטיסים אדומים',
    yellowCards: 'כרטיסים צהובים',
    posessionTime: 'החזקת כדור',
  }),
});

const alertFrequencies = new Map([
  ['daily', 'דיוור יומי',],
  ['twice_a_week', 'פעמיים בשבוע',],
  ['monthly', 'דיוור חודשי',],
  ['weekly', 'דיוור שבועי',],
]);

const subscribersAlertFrequencies = new Map([
  ['daily', ' דיוור יומי למנויים בלבד',],
  ['twice_a_week', 'דיוור דו-שבועי למינויים בלבד',],
  ['monthly', 'דיוור חודשי למינויים בלבד',],
  ['weekly', 'דיוור שבועי למינויים בלבד',],
]);

export const singleNewsletterI18n = Object.freeze({
  entryPageTitle: 'ניוזלטרים',
  loadMoreButton: 'טען עוד',
  sendButton: 'שליחה',
  recommended: 'דיוורים מומלצים עבורך',
  // TODO: change this like i18n in HTZ when we have it.
  subscribersFrequencyTypes: subscribersAlertFrequencies,
  frequencyTypes: alertFrequencies,
});

export const myAlertsI18n = Object.freeze({
  loadMore: 'טענו עוד',
  unsubscribe: 'הסרה',
  subscribe: 'הרשמה',
  promotion: 'לרכישה',
  titles: Object.freeze({
    main: 'ניוזלטרים והתראות',
    htz: 'הארץ',
    tm: 'TheMarker',
    hdc: 'Haaretz.com',
    authors: 'הכותבים שלי',
    tags: 'התגיות שלי',
    myAlerts: 'הניוזלטרים וההתראות שלי',
    recommendations: 'דיוורים מומלצים עבורך',
  }),
  subtitle: (name, isMyAlertsPage) => [
    `שלום${name ? ' ' : ''}${name || ''}, הנה כל הדיוורים `,
    isMyAlertsPage ? 'שנרשמת אליהם' : 'של TheMarker',
  ],
  nonSubscribersSubtitle: 'ברוכים הבאים לעמוד הדיוורים של TheMarker, כאן תוכלו לנהל את הדיוורים הנשלחים אליכם ולהרשם למה שמעניין אתכם',
  frequency: alertFrequencies,
  alertsLandingPageHref: '/newsletter/single?segmentId=0000017e-1b74-d2ef-a1ff-7ff4f9350000',
  alertsLandingPageLinkText: 'הניוזלטרים של TheMarker',
  myAlertsLink: '/account/my-alerts',
  empty: 'עדיין לא נרשמת לדיוורים',
  textBeforeLink: 'לרשימת כל הדיוורים - ',
  nonSubscribersPromotionTitle: 'מנויים מקבלים יותר! לקרוא ללא הגבלה, רק עם מינוי דיגיטלי ב TheMarker',
  linkText: 'התחבר כאן',
  nonSubscribersPromotionSubtitle: 'כבר רשום לאתר? ',
  promotionLink: 'https://promotion.themarker.com/?htm_source=site&htm_medium=purchase_button&htm_campaign=newsletters_page&htm_content=subscribe  ',
});

export const personalAreaI18n = Object.freeze({
  personalInfo: Object.freeze({
    title: {
      text: 'פרטים אישיים',
      showInPanel: true,
      onlyPaying: false,
    },
    form: Object.freeze({
      firstName: Object.freeze({
        label: 'שם פרטי',
        noteText: 'אנא הזינו שם פרטי',
        error: 'אנא הזינו שם פרטי',
        invalidError: 'יש להזין שם המורכב מ-2 אותיות לפחות וללא מספרים',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
      }),
      lastName: Object.freeze({
        label: 'שם משפחה',
        noteText: 'אנא הזינו שם משפחה',
        error: 'אנא הזינו שם משפחה',
        invalidError: 'יש להזין שם המורכב מ-2 אותיות לפחות וללא מספרים',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
      }),
      phone: Object.freeze({
        label: 'מספר טלפון נייד',
        requiredLong: 'אנא הזינו מספר טלפון נייד',
        requiredShort: '*',
        noteText: 'אנא הזינו מספר טלפון נייד',
        error: 'אנא הזינו מספר טלפון נייד',
        invalidError: 'אנא הזינו מספר טלפון נייד תקין',
      }),
      result: Object.freeze({
        success: 'הפרטים נשמרו בהצלחה',
        failed: 'אירעה שגיאה, נסו שוב',
      }),
    }),
  }),
  email: Object.freeze({
    title: {
      text: 'עדכון דוא"ל',
      showInPanel: false,
      onlyPaying: false,
    },
    form: Object.freeze({
      email: Object.freeze({
        label: 'דוא"ל',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
        noteText: 'אנא הזינו כתובת דוא”ל',
        buttonText: 'המשך',
        generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
        error: 'אנא הזינו כתובת דוא”ל',
        invalidError: 'אנא הזינו כתובת דוא”ל תקינה',
      }),
      result: Object.freeze({
        success: 'נשלח אלייך מייל לאימות',
        failed: 'אירעה שגיאה, נסו שוב',
      }),
    }),
  }),
  password: Object.freeze({
    title: {
      text: 'עדכון ושחזור סיסמה',
      showInPanel: false,
      onlyPaying: false,
    },
    form: Object.freeze({
      currentPassword: Object.freeze({
        label: 'סיסמה נוכחית',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
        noteText: 'אנא הזינו את הסיסמה הנוכחית',
        buttonText: 'המשך',
        generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
        error: 'אנא הזינו את הסיסמה הנוכחית',
        invalidError: 'אנא הזינו את הסיסמה הנוכחית התקינה',
      }),
      newPassword: Object.freeze({
        label: 'סיסמה חדשה',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
        noteText: 'אנא הזינו את הסיסמה החדשה',
        buttonText: 'המשך',
        generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
        error: 'אנא הזינו את הסיסמה החדשה',
        invalidError: 'אנא הזינו אנא הזינו את הסיסמה החדשה התקינה',
      }),
      newPasswordConfirm: Object.freeze({
        label: 'אימות סיסמה',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
        noteText: 'אנא הזינו את האימות הסיסמה',
        buttonText: 'המשך',
        generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
        error: 'אנא הזינו את האימות הסיסמה',
        invalidError: 'אנא הזינו את האימות הסיסמה התקינה',
        notEqual: 'הסיסמאות שהזנת אינן זהות',
      }),
      result: Object.freeze({
        success: 'סיסמתך שונתה בהצלחה',
        failed: 'אירעה שגיאה, נסו שוב',
      }),
    }),
    resetPassword: Object.freeze({
      button: {
        text: 'שכחתי סיסמה',
      },
      result: {
        success: 'מייל לעדכון סיסמה נשלח לכתובתך',
        failed: 'אירעה שגיאה, נסו שוב',
      },
    }),
  }),
  subscription: Object.freeze({
    title: {
      text: 'המינוי שלי',
      showInPanel: false,
      onlyPaying: true,
    },
    form: Object.freeze({
      reason: Object.freeze({
        error: 'אנא הזינו מספר טלפון נייד',
        invalidError: 'אנא הזינו מספר טלפון נייד תקין',
      }),
      result: Object.freeze({
        success: 'בקשתך לביטול המינוי נקלטה. יישלח אליך אימייל עם כל פרטי הביטול',
        failed: 'אירעה שגיאה, נסו שוב',
      }),
      errorMessagesMap: Object.freeze({
        MISSING_SUBS_NO: 'המינוי שלך בהקמה. מומלץ לנסות שוב בעוד כמה דקות',
      }),
    }),
    standByStage: Object.freeze({
      manageSubscruption: Object.freeze({
        text: 'לשדרוג המסלול',
      }),
      cancellationSubscription: 'לביטול המינוי',
      stopCancellationSubscription: 'ביטול בקשת הפסקה',
      subscriptions: Object.freeze({
        prefix: 'מינוי ',
        products: Object.freeze({
          1: 'עיתון הארץ יומי',
          2: 'עיתון הארץ אנגלית יומי',
          5: 'מגזין TheMarker',
          11: 'עיתון הארץ שישי',
          117: 'עיתון הארץ מהדורת חו"ל',
          215: 'עיתון TheMarker יומי',
          237: 'חבילת כלכלה וסופ"ש',
          238: 'עיתון הארץ שישי ודיגיטל',
          239: 'Haaretz Digital Subscription',
          243: 'הארץ בדיגיטל',
          273: 'TheMarker בדיגיטל',
          274: 'הארץ + TheMarker בדיגיטל',
          282: 'הארץ בדיגיטל',
        }),
        postfix: Object.freeze({
          trial: 'במסלול היכרות',
          monthly: ' במסלול חודשי',
          yearly: ' במסלול שנתי',
          other: '',
        }),
        apple: ' שנרכש באמצעות אפל',
        android: ' שנרכש באמצעות גוגל',
      }),
      appleHref: '/account/personal-area/apple',
      androidHref: '/account/personal-area/android',
      cancelledText: date => `גישתך לתכני TheMarker תופסק בתאריך ${date}`,
      cancelledTextWithoutDate: 'בקשתך לביטול המינוי נקלטה. יישלח אליך אימייל עם כל פרטי הביטול',
      changedPlan: 'בקשתך לעדכון המינוי נקלטה. יישלח אליך אימייל עם כל פרטי העסקה',
    }),
    selectReasonStage: Object.freeze({
      title: 'הפסקה עתידית של המינוי',
      subTitle: 'יש משהו שאנחנו יכולים לעשות?',
      text: 'שירות הלקוחות שלנו ישמח לעזור לך בכל בעיה!',
      contactByWhatsapp: 'לקבלת שירות בווטסאפ',
      contactOnSite: 'ליצירת קשר באתר',
      buttons: Object.freeze({
        continue: Object.freeze({
          text: 'רוצה לבטל',
        }),
      }),
    }),
    confirmationStage: Object.freeze({
      title: 'הפסקה עתידית של המינוי',
      subTitle: 'ביטול המינוי יחסום את הגישה שלך לכל תכני TheMarker הנה כמה מהם:',
      buttons: Object.freeze({
        continue: Object.freeze({
          text: 'רוצה לבטל',
        }),
        cancel: Object.freeze({
          text: 'התחרטתי',
        }),
      }),
    }),
    forceCancelButton: Object.freeze({
      text: 'ביטול מיידי וקבלת החזר',
      href: 'https://themarker.com/misc/contact-us?issue=digitalCancel',
    }),
    apple: Object.freeze({
      title: 'ביטול מינוי דרך Apple',
    }),
    android: Object.freeze({
      title: 'ביטול מינוי דרך Google',
    }),
  }),
  paymentsMethod: Object.freeze({
    title: {
      text: 'אמצעי תשלום',
      showInPanel: true,
      onlyPaying: true,
    },
    text: 'לעדכון פרטי כרטיס האשראי',
  }),
  personalInvoice: Object.freeze({
    months: ['ינו׳', 'פבר׳', 'מרץ', 'אפר׳', 'מאי', 'יוני', 'יולי', 'אוג׳', 'ספט׳', 'אוק׳', 'נוב׳', 'דצמ׳',],
    title: {
      text: 'שליחת חשבונית',
      showInPanel: true,
      onlyPaying: true,
    },
    form: Object.freeze({
      lastInvoice: ':חיוב אחרון',
      lastPrice: ':סכום',
      buttonText: 'שלח חשבונית למייל',
      periodicLabel: 'לשליחת חשבונית תקופתית',
      error: 'יש לבחור טווח של עד 12 חודשים',
      noteText: 'נא בחרו טווח של עד 12 חודשים',
      successSubmitMessage: 'בקשה להנפקת חשבונית נשלחה בהצלחה',
      errorSubmitMessage: 'אירעה שגיאה בבקשה להנפקת חשבונית. אנא נסו מאוחר יותר.',
      olderInvoiceText: 'לחשבוניות ישנות יותר אנא',
      olderInvoiceLinkText: 'צרו קשר עם שירות הלקוחות',
      email: Object.freeze({
        label: 'דוא"ל',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
        noteText: 'אנא הזינו כתובת דוא”ל',
        generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
        error: 'אנא הזינו כתובת דוא”ל',
        invalidError: 'אנא הזינו כתובת דוא”ל תקינה',
      }),
    }),
    date: Object.freeze({
      fromDate: 'החל מ-',
      untilDate: 'עד ל-',
    }),
  }),
  // invoiceGeneration: Object.freeze({
  //   title: 'הפקת חשבונית',
  //   form: Object.freeze({
  //     result: Object.freeze({
  //       success: '',
  //       failed: '',
  //     }),
  //   }),
  // }),
  support: Object.freeze({
    title: {
      text: 'תמיכה',
      showInPanel: false,
      onlyPaying: false,
    },
    buttons: [
      {
        text: 'תנאי שימוש',
        href: 'https://www.themarker.com/misc/site-policy',
        id: 0,
        actionCode: 69,
      },
      {
        text: 'צרו קשר',
        href: 'https://www.themarker.com/misc/contact-us',
        id: 1,
        actionCode: 122,
      },
    ],
  }),
  form: Object.freeze({
    button: Object.freeze({
      text: 'עדכון פרטים',
    }),
  }),
  urlText: 'האיזור האישי',
  cancellation: {
    thankYouForFeedback: 'תודה על המענה.',
    reasons: {
      title: 'חבל לנו לאבד אותך. נשמח להבין מהי סיבת הביטול',
      buttonText: 'המשך',
      continue: 'יש לסמן תשובה כדי להמשיך',
      radioButtons: {
        tooExpensive: 'מחיר יקר מידי ביחס לכמות הכתבות שקראתי',
        tooExpensive1: 'מנסה לחסוך בהוצאות בגלל המצב הכלכלי',
        technicalIssues: 'תקלות טכניות',
        noTime: 'אין לי זמן',
        nothingToRead: 'אין לי מה לקרוא',
        tooManyAds: 'יותר מדי פרסומות',
        paymentIssues: 'בעיה באמצעי תשלום',
        contentIssues: 'תוכן הכתבות באתר',
        specificStory: 'רכשתי מינוי כדי לקרוא כתבה מסוימת',
        other: 'מסיבה שאינה מצויינת כאן',
      },
    },
    feedback: {
      title: 'תודה ששיתפת! זה חשוב לנו',
      notSpecified: 'ללא פירוט',
      textBoxLabel: 'כאן אפשר לפרט',
      canWeContactYou: 'נשמח לחזור אליך עם פתרון שיתאים לך. אפשר?',
      buttons: {
        continue: 'לא, להמשך הביטול',
        contactMe: 'אוקיי, חזרו אלי',
      },
      acceptedPopup: {
        accepted: 'בקשתך התקבלה',
        trackingNumber: 'מזהה הפניה הוא: ',
        additionalDetails: 'נציג שירות יחזור אליך בהקדם האפשרי',
        backToHomepage: 'חזרה לעמוד הבית',
        contactUs: 'ליצירת קשר',
      },
      errorPopup: {
        error: 'אירעה שגיאה',
        contact: 'להמשך התהליך יש ליצור קשר עם ',
        customerService: 'שירות הלקוחות',
        backToPersonalArea: 'חזרה לאיזור האישי',
      },
    },
    readingSuggestions: {
      title: 'ביטול המינוי יחסום את גישתך לתכני TheMarker',
      subTitle: 'יש עוד כמה כתבות מעניינות שלא הספקת לקרוא:',
      continueButtonText: 'להמשך תהליך הביטול',
      cancelButtonText: 'התחרטתי',
    },
    newsletterSuggestions: {
      title: 'לא חייבים לקרוא הכל',
      subTitle: 'מומלץ להירשם לאחד הדיוורים שלנו, כדי שנעדכן רק במה שחשוב ומעניין',
      continueButtonText: 'להמשך תהליך הביטול',
      cancelButtonText: 'התחרטתי',
    },
    paymentSuggestion: {
      title: 'יש עוד דרכים לשלם',
      updateCreditCard: 'לעדכון פרטי כרטיס האשראי - ',
      clickHere: 'לחצו כאן',
      payUsingPayPal: '',
      talkToCustomerService: 'רוצה לדבר עם נציג שירות?',
      continueButtonText: 'להמשך תהליך הביטול',
      cancelButtonText: 'התחרטתי',
    },
    priceOffer: {
      title: 'לפני שנקבל החלטות דרמטיות',
      specialPrice: 'מחיר מיוחד',
      annual: 'מסלול שנתי',
      monthly: 'מסלול חודשי',
      cancelAnytime: 'ניתן לבטל בכל עת.',
      terms: 'תנאי רכישה',
      termsPopup: {
        understood: 'הבנתי',
      },
      changePlanRequestAcceptedPopup: {
        accepted: 'בקשתך לשינוי מסלול המינוי התקבלה',
        additionalDetails: ['ביממה הקרובה ישלח אליך הסכם התקשרות חדש', 'בתוך 8 ימים מהחיוב תשלח קבלה על התשלום',],
        backToHomepage: 'חזרה לעמוד הבית',
        contactUs: 'ליצירת קשר',
      },
      changePlanRequestErrorPopup: {
        error: 'אירעה שגיאה',
        contact: 'להמשך התהליך יש ליצור קשר עם ',
        customerService: 'שירות הלקוחות',
        backToPersonalArea: 'חזרה לאיזור האישי',
      },
      contactMe: {
        shallWeCallYou: 'מה דעתך שנתקשר אליך עם הצעה משתלמת במיוחד?',
        talkToMe: 'כן, דברו איתי',
        messageToCustomerSupport: 'בקשה לפני ביטול: חזרו אלי עם הצעה משתלמת',
        acceptedPopup: {
          accepted: 'בקשתך התקבלה',
          additionalDetails: 'נציג שירות יחזור אליך בהקדם האפשרי',
          backToHomepage: 'חזרה לעמוד הבית',
          contactUs: 'ליצירת קשר',
        },
        errorPopup: {
          error: 'אירעה שגיאה',
          contact: 'להמשך התהליך יש ליצור קשר עם ',
          customerService: 'שירות הלקוחות',
          backToPersonalArea: 'חזרה לאיזור האישי',
        },
      },

    },
    editorNote: {
      title: '',
      boxTitle: '',
      boxText: '',
      editorInChiefName: '',
      editorInChiefJobTitle: '',
      mailTo: '',
    },
    cancellationConfirmed: {
      title: '',
      subTitle: '',
      box: {
        title: '',
        subTitle: '',
        things: [
          { imageSrc: '', imageAlt: '', title: '', subTitle: '', href: '', },
          { imageSrc: '', imageAlt: '', title: '', subTitle: '', href: '', },
          { imageSrc: '', imageAlt: '', title: '', subTitle: '', href: '', },
          { imageSrc: '', imageAlt: '', title: '', subTitle: '', href: '', },
        ],
      },
      keepReading: {
        title: '',
        subTitle: '',
      },
      buttons: {
        keepMySubscription: '',
        contactUs: '',
        backToHomepage: '',
      },
      generalError: {
        error: '',
        contact: '',
        customerService: '',
        backToPersonalArea: '',
        newSubscriberMessage: '',
      },
      expireError: {
        title: '',
        subTitle: '',
        contactMe: '',
        backToHomepage: '',
      },
      contactMe: {
        messageToCustomerSupport: '',
        acceptedPopup: {
          accepted: '',
          trackingNumber: '',
          additionalDetails: '',
          backToHomepage: '',
          contactUs: '',
        },
        errorPopup: {
          error: '',
          contact: '',
          customerService: '',
          backToPersonalArea: '',
        },
      },
      personalAreaMessage: '',
    },
    cancellationButtons: {
      delayedCancellation: 'לביטול המינוי בתום תקופת החיוב',
      immediateCancellation: 'לביטול מיידי באמצעות פנייה לנציג שירות',
      keepMySubscription: '',
      cancellationRequestAcceptedPopup: {
        accepted: 'בקשתך לביטול המינוי התקבלה',
        additionalDetails: 'אישור ישלח אליך לאימייל',
        subscriptionWillEndAt: 'המינוי יסתיים בתאריך',
        backToHomepage: 'חזרה לעמוד הבית',
        finish: 'סיום',
        contactUs: 'ליצירת קשר',
        termsCheckBox: 'אני רוצה לקרוא עד 6 כתבות סגורות בחודש באתר ללא תשלום, ומאשר/ת קבלת דיוורים, עדכונים והצעות רכישת מינוי מאתרי הארץ-TheMarker',
      },
      cancellationRequestErrorPopup: {
        error: 'אירעה שגיאה',
        contact: 'להמשך התהליך יש ליצור קשר עם ',
        customerService: 'שירות הלקוחות',
        backToPersonalArea: 'חזרה לאיזור האישי',
        newSubscriberMessage: 'המינוי שלך בהקמה. מומלץ לנסות שוב בעוד כמה דקות',
      },
    },
  },
});

export const downloadApplicationPopupI18n = Object.freeze({
  title: Object.freeze({
    text: 'הכי טוב לקרוא אותנו',
    subText: 'באפליקציה',
  }),
  advantages: [
    'עקבו אחר הכותבים המועדפים עליכם',
    'קבלו פושים רק על מה שחשוב ומעניין',
    'האזינו לפודקאסט יומי',
    'גלשו במסך כהה במצב לילה',
  ],
  downloadButton: Object.freeze({
    text: 'קחו אותי לאפליקציה',
    link: Object.freeze({
      android: 'https://play.google.com/store/apps/details?id=com.themarker&hl=iw&gl=US',
      ios: 'https://apps.apple.com/il/app/themarker-%D7%93%D7%94%D7%9E%D7%A8%D7%A7%D7%A8/id360938308',
    }),
  }),
  closeButtonText: 'קרא בדפדפן',
});

export const sliderCaptionI18n = Object.freeze({
  by: 'על ידי',
  rightSide: 'מצד ימין',
  leftSide: 'מצד שמאל',
});

export const resetPasswordModalI18n = Object.freeze({
  email: Object.freeze({
    form: Object.freeze({
      email: Object.freeze({
        label: 'דוא"ל',
        requiredLong: 'שדה חובה',
        requiredShort: '*',
        noteText: 'אנא הזינו כתובת דוא”ל',
        buttonText: 'המשך',
        generalError: 'אירעה שגיאה. אנא נסה שנית במועד מאוחר יותר',
        error: 'אנא הזינו כתובת דוא”ל',
        invalidError: 'אנא הזינו כתובת דוא”ל תקינה',
      }),
      result: Object.freeze({
        success: 'נשלח אלייך מייל לאימות',
        failed: 'נשלח אלייך מייל לאימות',
      }),
    }),
  }),
  password: Object.freeze({
    resetPassword: Object.freeze({
      button: {
        text: 'שכחתי סיסמה',
      },
      header: 'החלפת סיסמה',
      resetPasswordButton: 'המשך',
      successMessage: 'הוראות לאיפוס הסיסמה נשלחו לתיבת הדוא"ל שלך',
      successButtonText: 'סגור',
      result: {
        success: 'מייל לעדכון סיסמה נשלח לכתובתך',
        failed: 'אירעה שגיאה, נסו שוב',
      },
    }),
  }),
});

export const magazineRecipesI18n = Object.freeze({
  more: () => 'להמלצות נוספות',
});

export const cancellationModalI18n = Object.freeze({
  header: 'Wait….',
  body: 'Read beyond the headlines by continuing your Haaretz subscription',
  backButtonText: 'Go Back',
  continueButtonText: 'Cancel Subscription',
});

export const authorTooltipI18n = Object.freeze({
  firstSentence: 'רוצה לקבל התראות על הכתבות החדשות של ',
  secondSentence: '? קליק על הפעמון וזה יקרה',
  close: 'סגירה',
});

export const conferenceArticleI18n = Object.freeze({
  redirectToFullArticle: 'לכתבה המלאה',
  headerH1: (title, conferenceDate) => `${conferenceDate ? `${conferenceDate} | ` : ''}${title}`,
});

export const giftShareI18n = Object.freeze({
  // shareKicker: '\u{1F381} קיבלת במתנה כתבה מ-TheMarker!',
  shareKicker: 'קיבלת במתנה כתבה מ-TheMarker',
  shareHeader: 'כתבה במתנה',
  shareCopyToClipboard: 'העתק את הלינק',
  shareWhatsapp: 'שתף בוואטסאפ',
  shareSummary: 'לשלוח את הכתבה ללא תשלום לחברים',
  shareExceeded: ['החל מחודש הבא ניתן יהיה לשלוח ', ' כתבות נוספות',],
  shareError: {
    GiftQuataExceededError: ['שלחת ', ' כתבות במתנה החודש.', 'החל מחודש הבא ניתן יהיה לשלוח ', ' כתבות נוספות',],
    GiftServiceError: 'לא ניתן לשלוח כרגע מתנות. אנא נסו שוב מאוחר יותר',
  },
  promotionText: 'רכשו מינוי ותוכלו לשלוח כתבות במתנה לחברים',
  promotionAction: 'לרכישה',
  introduction: ['חדש!', 'שתפו, והחברים שלכם יוכלו לקרוא כתבות של הארץ במתנה',],
});

export const appPromotionTooli18n = Object.freeze({
  downloadApp: 'אפליקציית TheMarker',
  useBrowser: 'המשך בדפדפן',
});

export const fomoToolTipI18n = Object.freeze({
  text: {
    tldr: 'רוצה להמשיך ליהנות מתקצירי כתבות?',
    giftArticle: 'רוצה להמשיך לשלוח כתבות במתנה?',
    comments: 'רוצה להמשיך ליהנות מקדימות בתגובות?',
    readingList: 'רוצה להמשיך ליהנות מהאפשרות לשמור כתבות?',
    button: 'לחידוש המינוי',
  },
  href: {
    tldr: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=banner&htm_campaign=fomo&htm_campaign_type=subscription&htm_content=tldr',
    giftArticle: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=banner&htm_campaign=fomo&htm_campaign_type=subscription&htm_content=gift-article',
    comments: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=banner&htm_campaign=fomo&htm_campaign_type=subscription&htm_content=new-comment',
    readingList: 'https://promotion.themarker.com/offers?htm_source=site&htm_medium=banner&htm_campaign=fomo&htm_campaign_type=subscription&htm_content=reading-list',
  },
});
